import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col } from "react-bootstrap";


import { FormattedMessage, useIntl } from "react-intl";
import { editSection, getSection } from "store/actions";
import Loader from "components/shared/Loader";
import { useState } from "react";
import FeaturesArray from "./FeaturesArray";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

const Section = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const { loading, error, section } = useSelector((state) => state.section);

  const { reset, handleSubmit } = useForm();
  const [features, setFeatures] = useState([]);

  // get single page by slug 
  useEffect(() => {
    dispatch(getSection({
      page: "about",
      slug: "features"
    }));
  }, [dispatch]);


  useEffect(() => {

    if (section?.features) {
      // const updatedSections = [];

      // Object.entries(section?.features).forEach(([key, section]) => {

      //   section.id = v4();
      //   section.image = process.env.REACT_APP_URL + section?.image.slice(1);
      //   updatedSections.push(section)
      // });

      setFeatures(section?.features)
    }

  }, [reset, section]);

  // error
  useEffect(() => {

    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }

  }, [error, formatMessage, locale]);


  const onSubmit = (data) => {

    // data.features = features.map(feature => {
    //   return { ...feature, image: `/uploads/${feature.image?.split("/").at(-1)}` }
    // })

    data.features = features;

    dispatch(editSection({ slug: "features", page: "about", data: { section: data } }));
  };


  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"features"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={9} md={12}>

                <FeaturesArray features={features} setFeatures={setFeatures} />

                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;
