import server from 'api/server';
import React from 'react'
import { v4 } from 'uuid';
import removeImg from "../../assets/svgs/close.svg";

const ImagesUpload = ({ imagesURLs, setImageURLs, title }) => {


  const onImageChange = (e) => {

    [...e.target.files].forEach(async (file) => {
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await server().post("/upload", formData);
      const image = {
        id: v4(),
        preview: URL.createObjectURL(file),
        path: process.env.REACT_APP_URL + data.data.file.path
      }

      setImageURLs((prevImages) => [...prevImages, image]);
    })

  }


  const removeSelectedImage = (imageId) => {
    const newImages = imagesURLs.filter(image => image.id !== imageId)
    setImageURLs(() => newImages);
  };

  return (
    <div>
      <label className="form-uploader">
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={onImageChange}
          id="filo"
        />
        <svg
          width="24"
          height="16"
          viewBox="0 0 24 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 16.0001H6.00004C2.87441 16.002 0.270931 13.604 0.0164008 10.4887C-0.238129 7.37347 1.94156 4.58465 5.02604 4.07911C6.44575 1.562 9.11015 0.00362534 12 5.65992e-05C13.8021 -0.00675485 15.5525 0.601415 16.962 1.72411C18.3461 2.82197 19.3301 4.34509 19.762 6.05811C22.3459 6.45514 24.1878 8.77563 23.9881 11.3822C23.7883 13.9888 21.6143 16.0015 19 16.0001ZM12 2.0001C9.83175 2.00267 7.83271 3.17221 6.76804 5.06111L6.30004 5.90011L5.35104 6.05511C3.30132 6.39852 1.85605 8.25441 2.02525 10.3258C2.19446 12.3972 3.92176 13.9939 6.00004 14.0001H19C20.5686 14.0017 21.8736 12.7947 21.9942 11.2308C22.1149 9.66685 21.0103 8.27401 19.46 8.03511L18.144 7.83511L17.822 6.54311C17.1573 3.86992 14.7546 1.99507 12 2.0001ZM13.45 12.0001H10.55V9.00011H8.00004L12 5.00011L16 9.00011H13.45V12.0001Z"
            fill="#2E3A59"
          />
        </svg>
        <span className="txt"></span>
      </label>
      <div className="images-wrp">
        {imagesURLs.map((image) => (
          <div key={image.id} className="img-holder">
            <img
              src={
                image?.preview || image?.path
              }
              width={100}
              height={100}
              alt=""
            />
            <button className="removeImg" onClick={(e) => {
              e.preventDefault();
              removeSelectedImage(image.id)
            }}>
              <img src={removeImg} alt="" />
            </button>

          </div>
        ))}
      </div>
    </div>
  )
}

export default ImagesUpload