import { toast } from "react-toastify";

export const toastErrorMessage = (message, locale = "ar") => {
  toast.error(message, {
    position: locale === "ar" ? "bottom-left" : "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}