import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { addSection, getAllPageSections } from "store/actions";
import ImageUpload from "components/shared/ImageUpload";
import { EditorComponent, Input } from "components/shared/FormComponents";
import AddButton from "components/shared/AddButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

import { getDateOfBirthWithFormat } from "helpers/functions";
import ImagesUpload from "components/shared/ImagesUpload";
import Select from "react-select";

const AddProject = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sections: categories, loading, error } = useSelector((state) => state.section);
  const [selectedImage, setSelectedImage] = useState({ preview: null, path: "" });
  const [description, setDescription] = useState({ ar: "", en: "" });
  const [slider, setSlider] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    dispatch(getAllPageSections("categories"));
  }, [dispatch]);

  // error 
  useEffect(() => {

    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }

  }, [error, formatMessage, locale]);


  const handleSelectOptions = (options) => {

    if (options.length < 0) return []

    return options.map((option) => {
      return {
        value: option?.slug,
        label: option?.heading?.[locale] || option?.heading
      }
    });
  }


  const onSubmit = (data) => {

    if (!selectedImage?.path) {
      toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
      return;
    }


    data.description = description;
    data.image = selectedImage?.path;
    data.title = data?.heading?.en;

    data.date = getDateOfBirthWithFormat(data.date);

    if (slider?.length > 0) {
      data.images = slider.map((image) => {
        return image.path;
      })
    }


    dispatch(addSection({ page: "projects", data: { section: data }, navigate }));
  };


  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"addProject"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* category  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5><FormattedMessage id={"category"} /> </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({ field: { onChange, value, name, ref, selected } }) => (
                            <Select
                              inputRef={ref}
                              styles={{
                                // Fixes the overlapping problem of the component
                                menu: provided => ({ ...provided, zIndex: 9999 })
                              }}
                              name={name}
                              placeholder={<FormattedMessage id={"category"} />}
                              options={handleSelectOptions(categories)}
                              onChange={(selectedOption) => {
                                // const selectedCategory = categories?.find(category => {
                                //   return category?.slug === selectedOption?.value
                                // });

                                onChange(selectedOption?.value);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"

                            />
                          )}
                          name={"category"}
                          rules={{ required: true }}
                        />
                      </div>

                      <p className="error-hint">
                        {errors?.category?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}

                      </p>

                    </div>
                  </Col>
                </Row>


                {/* image  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"image"} /></h5>

                      <ImageUpload selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                    </div>

                  </Col>
                </Row>


                {/*  slider */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5><FormattedMessage id={"slider"} /></h5>
                      <ImagesUpload imagesURLs={slider} setImageURLs={setSlider} />
                    </div>
                  </Col>
                </Row>



                {/* heading  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="heading.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="heading.en" register={register} errors={errors} />
                  </Col>
                </Row>



                {/* description  */}
                <Row>
                  <Col lg={12} xs={12}>

                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"arabic-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="description.ar"
                        render={({ field: { value } }) => (
                          <EditorComponent name="description.ar" locale="ar" setData={setDescription} />
                        )}
                      />
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>

                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"english-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="description.en"
                        render={({ field: { value } }) => (
                          <EditorComponent name="description" locale="en" setData={setDescription} />
                        )}
                      />
                    </div>
                  </Col>
                </Row>



                {/* client  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="client" name="client.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="client" name="client.en" register={register} errors={errors} />
                  </Col>
                </Row>

                {/* projectType  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="projectType" name="type.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="projectType" name="type.en" register={register} errors={errors} />
                  </Col>
                </Row>

                {/* city  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="city" name="city.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="city" name="city.en" register={register} errors={errors} />
                  </Col>
                </Row>

                {/* date  */}
                <Col lg={12} xs={12}>
                  <div className="form-group required">
                    <h5><FormattedMessage id={"date"} /> </h5>
                    <div>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref, selected } }) => (
                          <Flatpickr
                            className="form-control form-outline"
                            onChange={(selected) => {
                              onChange(selected);
                            }}
                            options={{
                              enableTime: false,
                              dateFormat: "Y-m-d",
                              // maxDate: getToday()
                            }}
                          />
                        )}
                        name={"date"}
                        rules={{ required: true }}
                      />


                    </div>
                    <p className="error-hint">
                      {errors?.date &&
                        <FormattedMessage id={"requiredField"} />}
                    </p>
                  </div>
                </Col>


                <AddButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProject;
