import { reorderSectionsApi } from 'api/section';
import { Card } from 'components/shared/DragAndDrop/Card';
import update from 'immutability-helper'
import { useEffect } from 'react';
import { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { Motion, spring } from 'react-motion';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteSection } from 'store/actions';


export const Table = ({ data }) => {
  const { locale, formatMessage } = useIntl();
  // eslint-disable-next-line no-lone-blocks
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCards(data)
  }, [data])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEditProject = (slug) => {
    navigate(`/projects/${slug}`);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDeleteProject = (slug) => {
    dispatch(deleteSection({ slug: slug, page: "projects" }));
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, [])

  const reorderSections = async (cards) => {
    await reorderSectionsApi({ page: "projects", data: { sections: cards } });

    toast.success(formatMessage({ id: "itemsAreArranged" }), {
      position: locale === "ar" ? "bottom-left" : "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    setIsLoading(false)
  }

  const onDrop = useCallback(() => {
    try {
      setIsLoading(true)
      reorderSections(cards);

    } catch (error) {
      setIsLoading(false)

      toast.error(formatMessage({ id: "sendingErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, formatMessage, locale]);

  const renderCard = useCallback((card, index) => {
    return (
      <Motion
        key={card.slug}
        style={{ y: spring(index * 80, { stiffness: 500, damping: 32 }) }}
      >
        {({ y }) => {
          return <Card
            index={index}
            handleEdit={handleEditProject}
            handleDelete={handleDeleteProject}
            id={card.slug}
            text={card.heading}
            card={card}
            moveCard={moveCard}
            onDrop={onDrop}
            style={{
              transform: 'translate3d(0, ' + y + 'px, 0)',
            }}
          />
        }


        }
      </Motion >
    )
  }, [handleDeleteProject, handleEditProject, moveCard, onDrop])
  return (
    <>

      <div className="dragable_table_body__ " >
        <div className='dragable_table_title__'>
          <FormattedMessage id={"title"} />
        </div>
        <div className='dragable_table__' >{cards?.map((card, i) => renderCard(card, i))}</div>
      </div>
      {
        isLoading ? <div className="lds-dual-ring"></div> : ""
      }
    </>
  )
}

export default Table;