import server from "./server";

export const getAllSubscribersApi = async () => {

  const response = await server().get(`/subscribers`);

  return response.data;
}

export const deleteSubscriberApi = async (id) => {
  const response = await server().delete(`/subscribers/${id}`);

  return response.data;
}