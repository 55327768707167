import ControlArea from 'components/Layout/ControlArea';
import Loader from 'components/shared/Loader';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllPageSections } from 'store/actions';
import Icon from "../../assets/svgs/services.svg";
import DndTable from './DndTable';
// import Table from './Table';

const Systems = () => {

  const { sections, loading, error } = useSelector((state) => state.section);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();

  useEffect(() => {
    dispatch(getAllPageSections("systems"));
  }, [dispatch]);

  // error 
  useEffect(() => {
    if (error) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error, formatMessage, locale]);


  if (loading) return <Loader />

  return (
    <>
      <div className="systems-wrap">
        <ControlArea
          btnTxt={<FormattedMessage id={"addSystem"} />}
          cardTxt={<FormattedMessage id={"systems"} />}
          icon={Icon}
          url="/systems/addSystem"
        />

        {/* <Table data={sections} loading={loading} /> */}
        <DndProvider backend={HTML5Backend}>
          <DndTable data={sections} />
        </DndProvider>
      </div>
    </>
  )
}

export default Systems

