import {
  GET_ALL_SOCIALS,
  GET_ALL_SOCIALS_SUCCESS,
  GET_ALL_SOCIALS_FAILURE,

  EDIT_SOCIAL,
  EDIT_SOCIAL_SUCCESS,
  EDIT_SOCIAL_FAILURE,

  DELETE_SOCIAL,
  DELETE_SOCIAL_SUCCESS,
  DELETE_SOCIAL_FAILURE,

  GET_SOCIAL,
  GET_SOCIAL_SUCCESS,
  GET_SOCIAL_FAILURE,

  ADD_SOCIAL,
  ADD_SOCIAL_SUCCESS,
  ADD_SOCIAL_FAILURE,

} from "./actionTypes";

export const getAllSocials = () => {
  return {
    type: GET_ALL_SOCIALS,
  };
}
export const getAllSocialsSuccess = (payload) => {
  return {
    type: GET_ALL_SOCIALS_SUCCESS,
    payload: payload,
  };
}

export const getAllSocialsFailure = (error) => {
  return {
    type: GET_ALL_SOCIALS_FAILURE,
    payload: error,
  };
}

export const editSocial = (payload) => {
  return {
    type: EDIT_SOCIAL,
    payload: payload
  };
}
export const editSocialSuccess = (payload) => {
  return {
    type: EDIT_SOCIAL_SUCCESS,
    payload: payload,
  };
}

export const editSocialFailure = (error) => {
  return {
    type: EDIT_SOCIAL_FAILURE,
    payload: error,
  };
}



export const getSocial = (payload) => {

  return {
    type: GET_SOCIAL,
    payload: payload
  };
}
export const getSocialSuccess = (payload) => {
  return {
    type: GET_SOCIAL_SUCCESS,
    payload: payload,
  };
}

export const getSocialFailure = (error) => {
  return {
    type: GET_SOCIAL_FAILURE,
    payload: error,
  };
}


export const deleteSocial = (id) => {

  return {
    type: DELETE_SOCIAL,
    payload: id
  }
}

export const deleteSocialSuccess = (id) => {
  return {
    type: DELETE_SOCIAL_SUCCESS,
    payload: id
  }
}

export const deleteSocialFailure = (error) => {
  return {
    type: DELETE_SOCIAL_FAILURE,
    payload: error
  }
}



export const addSocial = (payload) => {
  return {
    type: ADD_SOCIAL,
    payload: payload
  };
}
export const addSocialSuccess = (payload) => {
  return {
    type: ADD_SOCIAL_SUCCESS,
    payload: payload,
  };
}

export const addSocialFailure = (error) => {
  return {
    type: ADD_SOCIAL_FAILURE,
    payload: error,
  };
}
