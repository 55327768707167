const { default: server } = require("./server");

export const getAllSocialTypesApi = async () => {
  const response = await server().get("/settings/socialTypes");

  return response.data;
};

export const editSocialTypeApi = async ({ data, id }) => {

  const response = await server().put(`/settings/socialTypes/${id}`, data);

  return response.data;
};

export const getSocialTypeApi = async (id) => {
  const response = await server().get(`/settings/socialTypes/${id}`);

  return response.data;
};

export const deleteSocialTypeApi = async (id) => {
  const response = await server().delete(`/settings/socialTypes/${id}`);

  return response.data;
}

export const addSocialTypeApi = async (data) => {
  const response = await server().post("/settings/socialTypes", data);

  return response.data;
}
