import React, { useState } from "react";
import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import Arabic from "./lang/ar.json";
import English from "./lang/en.json";

const Wrapper = (props) => {

  // eslint-disable-next-line no-unused-vars
  const [locale, setLocale] = useState({
    lang: "ar",
    messages: Arabic,

  });
  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    const lang = localStorage.getItem("lang") || "ar"
    if (lang === "en") {
      setLocale({
        lang: "en",
        messages: English,
      });

      document.body.setAttribute("dir", "ltr");
      document.documentElement.lang = "en";
      document.querySelector('title').textContent = "Dashboard";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    // selectLanguage
    <Context.Provider value={{ locale, setLocale }} >
      <IntlProvider messages={locale.messages} locale={locale.lang} >
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export const Context = React.createContext();
export default Wrapper;
