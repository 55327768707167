import React from "react";
import removeImg from "assets/svgs/close.svg";
import { v4 } from "uuid";
import ImageUpdate from "./ImageUpdate";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import EditorDescription from "./EditorDescription";

const FeaturesArray = ({ features, setFeatures }) => {

  const handleInputOnChange = (e, id) => {
    const { name, value } = e.target;

    const newFeaturesArray = features.map((feature) => {
      if (feature.id !== id) return feature;

      // make an object with locals inside the big one
      const inputName = name?.split(".")?.[0];
      const local = name?.split(".")?.[1];

      if (local) {
        return {
          ...feature,
          [inputName]: {
            ...feature?.[inputName],
            [local]: value
          }
        };
      }

      return { ...feature, [name]: value };
    });

    setFeatures(newFeaturesArray);
  };

  const removeFeature = (featureId) => {
    const newFeatures = features.filter(
      (feature, index) => feature.id !== featureId
    );
    setFeatures(() => newFeatures);
  };

  const addFeature = () => {
    setFeatures(() => [
      ...features,
      { id: v4(), title: {}, description: {}, image: "" },
    ]);
  };

  return (
    <div className="form-group branches features_">
      <Row>
        <Col lg={12}>
          <h5>
            <FormattedMessage id={"features"} />
          </h5>

          {features.map((feature, index) => {

            return (

              <div key={feature.id} className="add-multi-component">
                <button
                  type="button"
                  onClick={() => removeFeature(feature.id)}
                  className="removeImg"
                >
                  <img src={removeImg} alt="" />
                </button>
                <Row>
                  <Col lg={12}>
                    <ImageUpdate
                      item={feature}
                      features={features}
                      setFeatures={setFeatures}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5> <FormattedMessage id={"title"} /> ( <FormattedMessage id={"arabic-language"} /> )</h5>
                      <input
                        type="text"
                        name="title.ar"
                        className="form-control form-outline"
                        onChange={(e) => handleInputOnChange(e, feature.id)}
                        value={feature?.title.ar}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5> <FormattedMessage id={"title"} /> ( <FormattedMessage id={"english-language"} /> )</h5>
                      <input
                        type="text"
                        name="title.en"
                        className="form-control form-outline"
                        onChange={(e) => handleInputOnChange(e, feature.id)}
                        value={feature?.title.en}
                      />
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5><FormattedMessage id={"description"} /> ( <FormattedMessage id={"arabic-language"} /> )</h5>
                      {/* <textarea
                        className="form-control form-outline"
                        name="description.ar"
                        onChange={(e) => handleInputOnChange(e, feature.id)}
                        value={feature?.description?.ar}
                      ></textarea> */}
                      {/* <input type="hidden" value={v4()} /> */}
                      <EditorDescription initialValue={feature?.description?.ar} locale={"ar"} handleInputOnChange={handleInputOnChange} featureId={feature.id} />
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5><FormattedMessage id={"description"} /> ( <FormattedMessage id={"english-language"} /> )</h5>
                      {/* <textarea
                        className="form-control form-outline"
                        name="description.en"
                        onChange={(e) => handleInputOnChange(e, feature.id)}
                        value={feature?.description?.en}
                      ></textarea> */}
                      <EditorDescription initialValue={feature?.description?.en} locale={"en"} handleInputOnChange={handleInputOnChange} featureId={feature.id} />

                      {/* <input type="hidden" value={v4()} /> */}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}

        </Col>

        <Col lg={4} xs={12} className="text-left">
          <button
            type="button"
            className="btn btn-green add_new_section__"
            onClick={() => {
              addFeature();
            }}
          >
            <FormattedMessage id={"addFeature"} />
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default FeaturesArray;

