import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { addSection } from "store/actions";
import ImageUpload from "components/shared/ImageUpload";
import { EditorComponent, Input } from "components/shared/FormComponents";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";


const AddCategory = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.section);
  const [selectedImage, setSelectedImage] = useState({ preview: null, path: "" });
  const [description, setDescription] = useState({ ar: "", en: "" });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // error 
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {

    if (!selectedImage?.path) {
      toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
      return;
    }
    data.image = selectedImage?.path;
    data.description = description;
    data.title = data?.heading?.en

    dispatch(addSection({ page: "categories", data: { section: data }, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"addCategory"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* image  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"image"} /></h5>
                      <ImageUpload selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                    </div>
                  </Col>
                </Row>

                {/* heading  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="heading.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="heading.en" register={register} errors={errors} />
                  </Col>
                </Row>

                {/* description  */}
                <Row>
                  <Col lg={12} xs={12}>

                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"arabic-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="description.ar"
                        render={({ field: { value } }) => (
                          <EditorComponent name="description.ar" locale="ar" setData={setDescription} />
                        )}
                      />
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>

                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"english-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="description.en"
                        render={({ field: { value } }) => (
                          <EditorComponent name="description" locale="en" setData={setDescription} />
                        )}
                      />
                    </div>
                  </Col>
                </Row>


                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"add"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
