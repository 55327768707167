import React, { useEffect, useState } from 'react'
import { editSettings, getAllSettings } from "store/actions";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from 'components/shared/Loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ImageUpload from 'components/shared/ImageUpload';
import { Input, Textarea } from 'components/shared/FormComponents';
import SaveChangesButton from 'components/shared/SaveChangesButton';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';


const Settings = () => {


  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();

  const { loading, error, settings } = useSelector((state) => state.settings);
  const [selectedAppLogo, setSelectedAppLogo] = useState({ preview: null, path: "" });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {

    reset({
      appName: settings?.appName,
      appDesc: settings?.appDesc,
      copyright: settings?.copyright,
      locationAddress: settings?.locationAddress,
      phone: settings?.phone,
      whatsup: settings?.whatsup,
      email: settings?.email,
      mapframe: settings?.mapframe,
    });

    setSelectedAppLogo({
      preview: null,
      path: settings?.appLogo,
    });

  }, [reset, settings]);

  useEffect(() => {
    dispatch(getAllSettings());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(<FormattedMessage id={"sendingErrorMessage"} />, {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error]);

  // error 
  useEffect(() => {

    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }

  }, [error, formatMessage, locale]);


  const onSubmit = (data) => {

    if (!selectedAppLogo?.path) {
      toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
      return;
    }
    data.appLogo = `/uploads/${selectedAppLogo?.path?.split("/").at(-1)}`;

    dispatch(editSettings(data));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"settings"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* image  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"appLogo"} /></h5>
                      <ImageUpload selectedImage={selectedAppLogo} setSelectedImage={setSelectedAppLogo} />

                    </div>

                  </Col>
                </Row>

                {/* appName  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="appName" name="appName.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="appName" name="appName.en" register={register} errors={errors} />
                  </Col>
                </Row>

                {/* appDesc  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Textarea langId="appDesc" name="appDesc.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Textarea langId="appDesc" name="appDesc.en" register={register} errors={errors} />
                  </Col>
                </Row>

                {/* locationAddress  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="locationAddress" name="locationAddress.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="locationAddress" name="locationAddress.en" register={register} errors={errors} />
                  </Col>
                </Row>

                {/* copyright  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="copyright" name="copyright.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="copyright" name="copyright.en" register={register} errors={errors} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="phone" name="phone" dir="ltr" register={register} errors={errors} />
                  </Col>

                  <Col lg={6} xs={12}>
                    <Input langId="whatsup" name="whatsup" dir="ltr" register={register} errors={errors} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} xs={12}>
                    <Input langId="email" name="email" dir="ltr" register={register} errors={errors} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} xs={12} >
                    <Textarea langId="mapframe" dir="ltr" name="mapframe" register={register} errors={errors} />
                  </Col>
                </Row>

                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings