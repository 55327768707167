import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";


import { FormattedMessage, useIntl } from "react-intl";
import { editSection, getSection } from "store/actions";
import Loader from "components/shared/Loader";
import { useState } from "react";
import ImageUpload from "components/shared/ImageUpload";
import { Input, Textarea } from "components/shared/FormComponents";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

const Section = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const { loading, error, section } = useSelector((state) => state.section);
  const [selectedImage, setSelectedImage] = useState({ preview: null, path: "" });
  const { register, reset, handleSubmit, formState: { errors }, } = useForm();



  // get single page by slug 
  useEffect(() => {
    dispatch(getSection({
      page: "home",
      slug: "about"
    }));
  }, [dispatch]);

  useEffect(() => {

    reset({
      heading: section?.heading,
      subTitle: section?.subTitle,
      description: section?.description,
      image: section?.image,

    });

    setSelectedImage({
      preview: null,
      path: section?.image,
    });

  }, [reset, section]);

  // error 
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);


  const onSubmit = (data) => {

    if (!selectedImage?.path) {
      toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
      return;
    }

    data.image = selectedImage?.path;

    dispatch(editSection({ slug: "about", page: "home", data: { section: data } }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"about"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>

                {/* image  */}
                <Col lg={12} xs={12}>
                  <div className="form-group required">
                    <h5><FormattedMessage id={"image"} /></h5>
                    <ImageUpload selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                  </div>
                </Col>



                {/* heading  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="heading.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="heading.en" register={register} errors={errors} />
                  </Col>
                </Row>

                {/* subTitle  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="subTitle" name="subTitle.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="subTitle" name="subTitle.en" register={register} errors={errors} />
                  </Col>
                </Row>

                {/* description */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Textarea langId="description" name="description.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Textarea langId="description" name="description.en" register={register} errors={errors} />
                  </Col>
                </Row>

                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;