import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_ALL_SOCIALTYPES,
  EDIT_SOCIALTYPE,
  DELETE_SOCIALTYPE,
  GET_SOCIALTYPE,
  ADD_SOCIALTYPE,
} from "./actionTypes";

import {
  getAllSocialTypesApi,
  editSocialTypeApi,
  deleteSocialTypeApi,
  getSocialTypeApi,
  addSocialTypeApi
} from "api/socialTypes";

import {
  getAllSocialTypesSuccess,
  getAllSocialTypesFailure,
  editSocialTypeSuccess,
  editSocialTypeFailure,
  deleteSocialTypeSuccess,
  deleteSocialTypeFailure,
  getSocialTypeSuccess,
  getSocialTypeFailure,
  addSocialTypeSuccess,
  addSocialTypeFailure,

} from "./actions"


// api calls 
function* getAllSocialTypes() {
  try {
    const { data } = yield call(getAllSocialTypesApi);

    yield put(getAllSocialTypesSuccess(data));
  } catch (error) {
    yield put(getAllSocialTypesFailure(error.response?.data?.message || error));
  }
}

function* editSocialType({ payload }) {


  try {
    const { data } = yield call(editSocialTypeApi, payload);

    yield put(editSocialTypeSuccess(data));
  } catch (error) {
    yield put(editSocialTypeFailure(error.response?.data?.message || error));
  }
}

function* getSocialType({ payload }) {

  try {
    const { data } = yield call(getSocialTypeApi, payload);

    yield put(getSocialTypeSuccess(data));
  } catch (error) {
    yield put(getSocialTypeFailure(error.response?.data?.message || error));
  }
}

function* deleteSocialType({ payload }) {
  try {
    yield call(deleteSocialTypeApi, payload);
    yield put(deleteSocialTypeSuccess(payload));


  } catch (error) {
    yield put(deleteSocialTypeFailure(error));
  }
}

function* addSocialType({ payload }) {

  try {
    const { data } = yield call(addSocialTypeApi, payload.data);

    yield put(addSocialTypeSuccess(data));

    payload.navigate("/socialTypes");
  } catch (error) {
    yield put(addSocialTypeFailure(error.response?.data?.message || error));
  }
}

// watchers
export function* watchgetAllSocialTypes() {
  yield takeEvery(GET_ALL_SOCIALTYPES, getAllSocialTypes);
}

export function* watcheditSocialType() {
  yield takeEvery(EDIT_SOCIALTYPE, editSocialType);
}
export function* watchGetSocialType() {
  yield takeEvery(GET_SOCIALTYPE, getSocialType);
}

export function* watchDeleteSocialType() {
  yield takeEvery(DELETE_SOCIALTYPE, deleteSocialType);
}

export function* watchAddSocialType() {
  yield takeEvery(ADD_SOCIALTYPE, addSocialType);
}



function* socialTypesSaga() {
  yield all([
    fork(watchgetAllSocialTypes),
    fork(watcheditSocialType),
    fork(watchDeleteSocialType),
    fork(watchGetSocialType),
    fork(watchAddSocialType),
  ]);
}

export default socialTypesSaga;
