import { combineReducers } from "redux";

// Authentication
import authentication from "./authentication/reducer";
import settings from "./settings/reducer"
import section from "./section/reducer";
import subscribers from "./subscribers/reducer";
import socialTypes from "./socialTypes/reducer";
import socials from "./socials/reducer";


const rootReducer = combineReducers({
  authentication,
  section,
  settings,
  subscribers,
  socialTypes,
  socials
});

export default rootReducer;
