import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

const AddButton = () => {
  return (
    <Row>
      <Col lg={4} xs={12}>
        <div className="form-group">
          <button type="submit" className="btn btn-blue">
            <FormattedMessage id={"add"} />
          </button>
        </div>
      </Col>
    </Row>
  )
}

export default AddButton