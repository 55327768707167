import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";


import { FormattedMessage, useIntl } from "react-intl";
import { editSection, getSection } from "store/actions";
import Loader from "components/shared/Loader";
import { useState } from "react";
import ImageUpload from "components/shared/ImageUpload";
import { EditorComponent } from "components/shared/FormComponents";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

const Section = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const { loading, error, section } = useSelector((state) => state.section);
  const [selectedImage, setSelectedImage] = useState({ preview: null, path: "" });
  const { control, reset, handleSubmit } = useForm();
  const [description, setDescription] = useState({ ar: "", en: "" });


  // get single page by slug 
  useEffect(() => {
    dispatch(getSection({
      page: "about",
      slug: "why-us"
    }));
  }, [dispatch]);

  useEffect(() => {

    reset({
      description: section?.description,
      image: section?.image
    });

    setSelectedImage({
      preview: null,
      path: section?.image,
    });

    setDescription(section?.description)
  }, [reset, section]);

  // error 
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }

  }, [error, formatMessage, locale]);


  const onSubmit = (data) => {

    if (!selectedImage?.path) {
      toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
      return;
    }
    data.description = description;
    data.image = selectedImage?.path;

    dispatch(editSection({ slug: "why-us", page: "about", data: { section: data } }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"whyUs"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>

                {/* image  */}
                <Col lg={12} xs={12}>
                  <div className="form-group required">
                    <h5><FormattedMessage id={"image"} /></h5>
                    <ImageUpload selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                  </div>

                </Col>


                {/* description  */}
                <Row>
                  <Col lg={12} xs={12}>

                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"arabic-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="description.ar"
                        render={({ field: { value } }) => (
                          <EditorComponent name="description.ar" locale="ar" setData={setDescription} initialValue={section?.description?.ar} />
                        )}
                      />
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>

                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"english-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="description.en"
                        render={({ field: { value } }) => (
                          <EditorComponent name="description" locale="en" setData={setDescription} initialValue={section?.description?.en} />
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;