import { Editor } from '@tinymce/tinymce-react';
import React from 'react'
import { useState } from 'react';

const EditorDescription = ({ initialValue = "", locale = "", handleInputOnChange, featureId }) => {
  const [initValue] = useState(initialValue);

  return (<Editor
    initialValue={initValue}
    onChange={(e, editor) => {
      const data = editor.getContent();
      const descriptionData = {
        target: {
          value: data,
          name: `description.${locale}`
        }
      }
      handleInputOnChange(descriptionData, featureId)
    }}
    tinymceScriptSrc="https://cdn.tiny.cloud/1/qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc/tinymce/5-stable/tinymce.min.js"
    init={{
      plugins:
        "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
      menubar:
        "file edit view insert format tools table help",
      toolbar:
        "customInsertButton | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | insertfile image media link table | ltr rtl | language",
      toolbar_sticky: true,
      autosave_ask_before_unload: true,
      autosave_interval: "30s",
      autosave_prefix: "{path}{query}-{id}-",
      autosave_restore_when_empty: false,
      autosave_retention: "2m",
      mobile: {
        menubar: true,
      },
      directionality: "ltr",
      language: `${locale}`,
      height: 400,
      image_caption: true,
      quickbars_selection_toolbar:
        "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
      noneditable_noneditable_class: "mceNonEditable",
      toolbar_mode: "wrap",
      contextmenu: "link image imagetools table",
      content_style: "body {font - size:14px}",
      setup: function (editor) {
        editor.ui.registry.addButton(
          "customInsertButton",
          {
            text: "custom Button",
            onAction: function (_) {
              editor.insertContent(
                `&nbsp;<a href="" class="btn" style="    background: linear-gradient(
                148deg,#1d5cd1,#0ba1d8 84%);
              color: #fff;
              padding: 10px 20px;
              border-radius: 50px;
              display: inline-block;
              cursor: pointer;
              text-decoration: none;">my button!</a>&nbsp;`
              );
            },
          }
        );
      },
    }}
  />
  )
}

export default EditorDescription