import server from "./server";

export const postUserLogin = async (data) => {

  const response = await server().post("/auth/login", data);
  return response.data;
};

export const getCurrentUser = async (data) => {
  const response = await server().get("/auth/currentUser", data);

  return response.data;
};

