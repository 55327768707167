export const GET_ALL_SOCIALTYPES = "GET_ALL_SOCIALTYPES";
export const GET_ALL_SOCIALTYPES_SUCCESS = "GET_ALL_SOCIALTYPES_SUCCESS";
export const GET_ALL_SOCIALTYPES_FAILURE = "GET_ALL_SOCIALTYPES_FAILURE";

export const EDIT_SOCIALTYPE = "EDIT_SOCIALTYPE";
export const EDIT_SOCIALTYPE_SUCCESS = "EDIT_SOCIALTYPE_SUCCESS";
export const EDIT_SOCIALTYPE_FAILURE = "EDIT_SOCIALTYPE_FAILURE";

export const GET_SOCIALTYPE = "GET_SOCIALTYPE";
export const GET_SOCIALTYPE_SUCCESS = "GET_SOCIALTYPE_SUCCESS";
export const GET_SOCIALTYPE_FAILURE = "GET_SOCIALTYPE_FAILURE";

export const DELETE_SOCIALTYPE = "DELETE_SOCIALTYPE";
export const DELETE_SOCIALTYPE_SUCCESS = "DELETE_SOCIALTYPE_SUCCESS";
export const DELETE_SOCIALTYPE_FAILURE = "DELETE_SOCIALTYPE_FAILURE";

export const ADD_SOCIALTYPE = "ADD_SOCIALTYPE";
export const ADD_SOCIALTYPE_SUCCESS = "ADD_SOCIALTYPE_SUCCESS";
export const ADD_SOCIALTYPE_FAILURE = "ADD_SOCIALTYPE_FAILURE";