const { default: server } = require("./server");

export const getAllSettingsApi = async () => {
  const response = await server().get("/settings/siteInfo");

  return response.data;
};

export const editSettingsApi = async (data) => {
  const response = await server().put("/settings/siteInfo", data);

  return response.data;
};
