import {
  GET_ALL_SOCIALS,
  GET_ALL_SOCIALS_SUCCESS,
  GET_ALL_SOCIALS_FAILURE,

  EDIT_SOCIAL,
  EDIT_SOCIAL_SUCCESS,
  EDIT_SOCIAL_FAILURE,

  DELETE_SOCIAL,
  DELETE_SOCIAL_SUCCESS,
  DELETE_SOCIAL_FAILURE,
  GET_SOCIAL,
  GET_SOCIAL_SUCCESS,
  GET_SOCIAL_FAILURE,
  ADD_SOCIAL,
  ADD_SOCIAL_SUCCESS,
  ADD_SOCIAL_FAILURE,

} from "./actionTypes";


const initialState = {
  socials: [],
  loading: false,
  error: "",
  social: {}
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all socials  
    case GET_ALL_SOCIALS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_ALL_SOCIALS_SUCCESS:

      state = {
        ...state,
        loading: false,
        socials: action.payload.socials

      };
      break;

    case GET_ALL_SOCIALS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;

    // edit social type 
    case EDIT_SOCIAL:
      state = { ...state, loading: true }

      break;

    case EDIT_SOCIAL_SUCCESS:
      state = {
        ...state,
        loading: false,
        social: action.payload.social
      }
      break;

    case EDIT_SOCIAL_FAILURE:
      state = {
        ...state, loading: false, error: action.payload
      }
      break;

    // get social type 
    case GET_SOCIAL:
      state = { ...state, loading: true }

      break;

    case GET_SOCIAL_SUCCESS:

      state = {
        ...state,
        loading: false,
        social: action.payload
      }
      break;

    case GET_SOCIAL_FAILURE:
      state = {
        ...state, loading: false, error: action.payload
      }
      break;

    // delete social 
    case DELETE_SOCIAL:
      state = {
        ...state,
        loading: true
      }

      break;

    case DELETE_SOCIAL_SUCCESS:

      const socialsAfterDeleteing = [
        ...state?.socials?.filter((social) => social?.id !== action.payload),
      ];

      state = {
        ...state,
        loading: false,
        socials: socialsAfterDeleteing
      }
      break;

    case DELETE_SOCIAL_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break;

    // edit social type 
    case ADD_SOCIAL:
      state = { ...state, loading: true }

      break;

    case ADD_SOCIAL_SUCCESS:
      state = {
        ...state,
        loading: false,
        social: action.payload.social
      }
      break;

    case ADD_SOCIAL_FAILURE:
      state = {
        ...state, loading: false, error: action.payload
      }
      break;


    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
