import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { getSection } from "store/actions";
import Loader from "components/shared/Loader";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

const ShowContact = () => {

  const dispatch = useDispatch();
  const { id } = useParams();
  // const { loading, singleContact } = useSelector((state) => state.contacts);
  const { loading, error, section } = useSelector((state) => state.section);
  const { formatMessage, locale } = useIntl();

  useEffect(() => {
    dispatch(getSection({ page: "contact", slug: id }));

  }, [dispatch, id]);

  // error 
  useEffect(() => {

    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }

  }, [error, formatMessage, locale]);

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card job-details">
          <div className="card-head">
            <h4><FormattedMessage id={"showContact"} /></h4>
          </div>
          <div className="card-body table-details--">
            <div className="table-responsive">
              <table className="table table-bordered">

                <tbody>
                  <tr>
                    <td> <FormattedMessage id={"name"} /></td>
                    <td>{section?.name} </td>
                  </tr>
                  <tr>
                    <td> <FormattedMessage id={"email"} /></td>
                    <td>{section?.email} </td>
                  </tr>
                  <tr>
                    <td> <FormattedMessage id={"message"} /></td>
                    <td>{section?.message} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>



      </div>
    </>
  );
};

export default ShowContact;
